import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import { Parallax, Background } from 'react-parallax';
import Decor from '../media/Decor_White_Video_Screen.svg';
import MP4 from '../media/1.mp4';
import webm from '../media/3.webm';
import ogv from '../media/2.ogv';
import videoBG from '../media/video.jpg';

const Video = ({ classes }) => {
  return (
    <Parallax strength={100}>
      <Background>
        <video autoPlay loop muted poster={videoBG} className={classes.video}>
          <source src={MP4} type='video/mp4' />
          <source src={webm} type='video/webm' />
          <source src={ogv} type='video/ogg' />
        </video>
      </Background>
      <div
        className={classNames(
          classes.root,
          'd-flex justify-content-center align-items-center'
        )}
      >
        <div className={classNames('text-center', classes.hero)}>
          <h1 className={classes.videoHeader}>
            Baumfällungen
            <br />
            aller Art
          </h1>
          <div
            className={classNames(
              'd-flex justify-content-between align-items-center',
              classes.decor
            )}
          >
            <div className={classNames(classes.stripe)} />
            <div className='px-4'>
              <img src={Decor} alt='' />
            </div>
            <div className={classNames(classes.stripe)} />
          </div>
          <div className={classNames(classes.seit)}>Seit 1994</div>
        </div>
      </div>
    </Parallax>
  );
};

const style = theme => ({
  video: { minWidth: '100vw' },
  root: {
    minHeight: 730,
    color: '#ffffff',
    [theme.bp.down('md')]: {
      minHeight: 542
    }
  },
  hero: {
    position: 'relative',
    top: -42,
    [theme.bp.down('md')]: {
      top: 16
    }
  },
  videoHeader: {
    textTransform: 'uppercase',
    fontSize: 58,
    fontWeight: 'bold',
    lineHeight: '58px',
    marginBottom: 25,
    [theme.bp.down('md')]: {
      fontSize: 40,
      lineHeight: '46px',
      position: 'relative',
      top: -5,
      marginBottom: 0
    }
  },
  decor: {
    padding: [0, 153],
    [theme.bp.down('md')]: {
      '& img': {
        width: 50
      },
      padding: [0, 14]
    }
  },
  stripe: {
    backgroundColor: '#fff',
    flexGrow: 1,
    height: 5,
    [theme.bp.down('md')]: {
      height: 3
    }
  },
  seit: {
    marginTop: 30,
    fontSize: 32,
    [theme.bp.down('md')]: {
      marginTop: 20,
      fontSize: 20
    }
  }
});

export default injectSheet(style)(Video);
