import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import injectSheet from 'react-jss';

const Footer = ({ classes }) => {
  return (
    <Container fluid className={classes.root}>
      <Container>
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <div className={classes.mark}>Gaidamak Forsters Baumpflege</div>
            <div className={classes.phone}>09153 / 979941</div>
            <div className={classes.text}>
              <div>
                Kersbacher Str. 8<br />
                91233 Neunkirchen a. Sand{' '}
              </div>
              <div>v.gaidamak@web.de</div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const style = (theme) => ({
  root: {
    paddingTop: 25,
    paddingBottom: 50,
    color: '#fff',
    backgroundColor: '#57574a',
    [theme.bp.down('md')]: {
      paddingTop: 34,
    },
  },
  mark: {
    fontSize: 23,
    textAlign: 'center',
    [theme.bp.down('md')]: {
      fontSize: 16,
    },
  },
  phone: {
    fontSize: 28,
    marginTop: 22,
    marginBottom: 26,
    textAlign: 'center',
    [theme.bp.down('md')]: {
      fontSize: 17,
      marginTop: 14,
      marginBottom: 10,
    },
  },
  text: {
    fontSize: 20,
    '& div': {
      textAlign: 'center',
    },
    [theme.bp.down('md')]: {
      fontSize: 14,
    },
  },
});

export default injectSheet(style)(Footer);
