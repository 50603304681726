import emailValidator from 'email-validator';

export const required = value => (!!value ? undefined : 'Поле обязательно');
export const validateEmail = email =>
  !!email && !emailValidator.validate(email)
    ? 'Некорректный формат e-mail'
    : undefined;

export const langs = {
  ru: {
    regex: /^[а-яА-ЯёЁ-\s]+$/,
    error: 'Разрешены только русские буквы'
  },
  'not-en': {
    regex: /^[^a-zA-Z<>]+$/,
    error: 'Разрешены только русские буквы'
  },
  'not-ru': {
    regex: /^[^а-яА-ЯёЁ<>]+$/,
    error: 'Разрешены только латинские буквы'
  },
  en: {
    regex: /^[a-zA-Z-\s]+$/,
    error: 'Разрешены только латинские буквы'
  },
  'ru-en': {
    regex: /^[a-zA-Zа-яА-ЯёЁ-\s]+$/,
    error: 'Разрешены только русские и латинские буквы'
  },
  email: {
    regex: /^[a-zA-Z0-9._%+-@]+$/,
    error: 'Разрешены только латинские буквы'
  },
  digit: {
    regex: /^[0-9]+$/,
    error: 'Разрешены только цифры'
  }
};

// taken from 'material-ui' library and modified for using with bootstrap 3 breakpoints
export function createBreakpoints() {
  const keys = ['xs', 'sm', 'md', 'lg'];

  const values = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200
  };

  const unit = 'px';

  const step = 5;

  function up(key) {
    const value = typeof values[key] === 'number' ? values[key] : key;
    return `@media (min-width:${value}${unit})`;
  }

  function down(key) {
    const endIndex = keys.indexOf(key) + 1;
    const upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      // xl down applies to all sizes
      return up('xs');
    }

    const value =
      typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
    return `@media (max-width:${value - step / 100}${unit})`;
  }

  function between(start, end) {
    const endIndex = keys.indexOf(end) + 1;

    if (endIndex === keys.length) {
      return up(start);
    }

    return (
      `@media (min-width:${values[start]}${unit}) and ` +
      `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
    );
  }

  function only(key) {
    return between(key, key);
  }

  function width(key) {
    return values[key];
  }

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    width
  };
}
