import React from 'react';
import { Field, reduxForm } from 'redux-form';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import emailvalidator from 'email-validator';
import send from '../media/sent.svg';

class selectBox extends React.Component {
  state = {
    open: false
  };

  toggle = () => this.setState(state => ({ open: !state.open }));
  close = () => this.setState(state => ({ open: false }));
  onSelect = value => {
    const { input } = this.props;
    input.onFocus();
    input.onChange(value);
    input.onBlur();
    this.setState({ open: false });
  };

  render() {
    const { classes, input, meta } = this.props;
    const open = this.state.open;
    return (
      <>
        <label className={classes.label}>
          Anrede<sup>*</sup>
        </label>
        <div
          className={classNames(
            classes.row,
            !open && !!meta.error && meta.submitFailed && classes.errorField
          )}
        >
          <div>
            <div
              className={classNames(
                'position-relative',
                open && 'bg-white',
                classes.radius
              )}
            >
              <div className={classNames(classes.select)}>
                {!!input.value && !open ? (
                  <div onClick={this.toggle} className={classes.selected}>
                    {input.value}
                  </div>
                ) : (
                  <div onClick={this.toggle}>Bitte wählen Sie:</div>
                )}
                <div
                  className={classNames(
                    classes.selectArrow,
                    !!input.value && classes.selectedArrow
                  )}
                  tabIndex='-1'
                  onClick={this.toggle}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </div>
              {open && (
                <div className={classNames(classes.select, classes.selectMenu)}>
                  <div className='position-relative'>
                    <div
                      className={classes.selectItem}
                      onClick={() => this.onSelect('Herr')}
                    >
                      Herr
                    </div>
                    <div
                      className={classes.selectItem}
                      onClick={() => this.onSelect('Frau')}
                    >
                      Frau
                    </div>
                    <div className={classes.band} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

class InputField extends React.Component {
  static defaultProps = {
    type: 'text',
    placeholder: '',
    label: '',
    req: false
  };
  render() {
    const { classes, input, meta, req, label, type, placeholder } = this.props;
    return (
      <>
        <label className={classes.label}>
          {label}
          {req && <sup>*</sup>}
        </label>
        <div
          className={classNames(
            classes.row,
            meta.error && meta.submitFailed && classes.errorField
          )}
        >
          <div>
            <input
              id={input.name}
              type={type}
              placeholder={placeholder}
              className={classes.input}
              {...input}
            />
          </div>
        </div>
      </>
    );
  }
}

class TextAreaField extends React.Component {
  static defaultProps = {
    placeholder: '',
    label: '',
    req: false,
    rows: 5
  };
  render() {
    const { classes, input, meta, req, label, placeholder, rows } = this.props;
    return (
      <div
        className={classNames(
          classes.row,
          'mb-0',
          meta.error && meta.submitFailed && classes.errorField
        )}
      >
        <label className={classes.label}>
          {label}
          {req && <sup>*</sup>}
        </label>
        <div>
          <textarea
            id={input.name}
            rows={rows}
            placeholder={placeholder}
            className={classes.input}
            {...input}
          />
        </div>
      </div>
    );
  }
}

const SimpleForm = props => {
  const {
    handleSubmit,
    classes,
    submitting,
    submitSucceeded,
    submitFailed
  } = props;
  if (submitSucceeded) {
    return (
      <div className={classes.success}>
        <div className={classes.sent}>
          <img src={send} alt='sent' />
        </div>
        <div>Danke für Ihre Anfrage!</div>
        <div>Die Nachricht wurde erfolgreich versandt.</div>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <div className={classes.subHead}>
        {submitFailed ? (
          <span style={{ color: 'red' }}>Bitte prüfen Sie Ihre Angaben</span>
        ) : (
          'Ich melde mich bei Ihnen:'
        )}
      </div>
      <Field
        name='andrede'
        component={selectBox}
        className={classes.input}
        classes={classes}
      />
      <Field
        name='name'
        component={InputField}
        placeholder='Ihr Vorname und Nachname'
        label='Name'
        req={true}
        classes={classes}
      />
      <Field
        name='plz'
        component={InputField}
        placeholder='Wo wohnen Sie?'
        label='PLZ, Ort'
        req={true}
        classes={classes}
      />
      <Field
        name='phone'
        component={InputField}
        placeholder='Ihr Telefonnummer für Rückfragen'
        label='Telefon'
        req={true}
        classes={classes}
      />
      <Field
        name='email'
        component={InputField}
        placeholder='Ihr E-Mail Adressen'
        label='E-Mail'
        type='text'
        req={false}
        classes={classes}
      />
      <Field
        name='message'
        component={TextAreaField}
        placeholder='Was kann ich für Sie tun?'
        label='Nachricht'
        type='text'
        req={false}
        classes={classes}
      />
      <div className='mt-2'>*Pflichtfelder</div>
      <div className='text-center'>
        <button type='submit' className={classes.submit} disabled={submitting}>
          SENDEN
        </button>
      </div>
    </form>
  );
};

const styles = theme => ({
  success: {
    fontSize: 21,
    textAlign: 'center',
    paddingBottom: 150
  },
  sent: {
    margin: [70, 0, 70]
  },
  subHead: {
    fontSize: 25,
    textAlign: 'center',
    paddingBottom: 25,
    [theme.bp.down('md')]: {
      fontSize: 20,
      paddingBottom: 12
    }
  },
  error: {
    position: 'absolute',
    color: 'red'
  },
  errorField: {
    border: '1px solid red !important',
    boxShadow: '0px 0px 5px 0px red !important'
  },
  root: {
    paddingBottom: 40,
    [theme.bp.down('md')]: {
      paddingBottom: 20,
      margin: [0, 57]
    }
  },
  label: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'center',
    display: 'block',
    [theme.bp.down('md')]: {
      fontSize: 16,
      lineHeight: '21px'
    }
  },
  input: {
    width: '100%',
    padding: [0, 10],
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: 'white',
    textAlign: 'center',
    minHeight: 30,
    [theme.bp.down('md')]: {
      fontSize: 13,
      minHeight: 16
    }
  },
  row: {
    marginBottom: 25,
    [theme.bp.down('md')]: {
      marginBottom: 5
    },
    border: '1px solid transparent',
    boxShadow: '0px 0px 5px 0px transparent'
  },
  selectRoot: {
    height: 28,
    display: 'flex',
    width: '100%',
    borderRadius: 3
  },

  select: {
    width: '100%',
    padding: [0, 10],
    '-webkit-writing-mode': 'horizontal-tb !important',
    'text-rendering': 'auto',
    color: '#757575',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textTransform: 'none',
    textIndent: 0,
    textShadow: 'none',
    display: 'inline-block',
    textSlign: 'start',
    backgroundColor: 'white',
    '-webkit-rtl-ordering': 'logical',
    cursor: 'pointer !important',
    margin: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'white',
    borderImage: 'initial',
    borderRadius: 3,
    textAlign: 'center',
    position: 'relative',
    [theme.bp.down('md')]: {
      fontSize: 13
    }
  },
  selectArrow: {
    top: -2,
    right: -2,
    width: 28,
    bottom: -2,
    position: 'absolute',
    backgroundColor: '#c6dfab',
    borderRadius: 3,
    padding: [2, 0],
    cursor: 'pointer !important',
    '&:focus': {
      outline: 0
    },
    color: 'white',
    '& .fa-caret-down': {
      fontSize: 24
    },
    [theme.bp.down('md')]: {
      width: 20,
      '& .fa-caret-down': {
        fontSize: 18
      }
    }
  },
  selectedArrow: {
    backgroundColor: '#6b8350'
  },
  selectMenu: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 27,
    borderRadius: [0, 0, 3, 0],
    zIndex: 2,
    padding: 0,
    [theme.bp.down('md')]: {
      top: 23
    }
  },
  selectItem: {
    maxWidth: 115,
    margin: '0 auto',
    borderTop: '1px solid #7575759c',
    padding: [2, 0],
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  },
  radius: {
    borderRadius: 3
  },
  band: {
    position: 'absolute',
    top: -2,
    right: -2,
    width: 28,
    bottom: -2,
    backgroundColor: '#f0f0f0',
    borderRadius: [0, 0, 3, 0],
    [theme.bp.down('md')]: {
      width: 20
    }
  },
  selected: {
    color: '#000 !important'
  },
  submit: {
    display: 'inline-block',
    padding: [5, 17],
    fontSize: 18,
    marginTop: 10,
    marginBottom: 26,
    backgroundColor: theme.grey,
    border: 'none',
    color: '#fff',
    [theme.bp.down('md')]: {
      fontSize: 16
    },
    '&:focus': {
      backgroundColor: 'white',
      color: '#000'
    },
    '&:hover': {
      backgroundColor: '#3f3e33',
      color: 'white'
    }
  }
});

const validate = values => {
  const errors = {};
  const { andrede, phone, name, plz, email } = values;
  if (!andrede) errors.andrede = 'Pflichtfelder';
  if (!phone) {
    errors.phone = 'Pflichtfelder';
  } else {
    if (!/^[0-9\s-()+]+$/.test(phone)) {
      errors.phone = 'Pflichtfelder';
    }
  }
  if (!name) {
    errors.name = 'Pflichtfelder';
  } else {
    const arrName = name.split(' ');
    if (arrName.length < 2 || arrName[0].length < 2 || arrName[1].length < 2) {
      errors.name = 'Pflichtfelder';
    }
    if (/[0-9]/.test(name)) {
      errors.name = 'Pflichtfelder';
    }
  }
  if (!plz) errors.plz = 'Pflichtfelder';
  if (!!email && !emailvalidator.validate(email)) {
    errors.email = 'Pflichtfelder';
  }
  return errors;
};

export default reduxForm({
  form: 'contact', // a unique identifier for this form,
  validate
})(injectSheet(styles)(SimpleForm));
