import React from 'react';
import { Container, Row, Col, NavbarToggler } from 'reactstrap';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from '../media/Logo_Screen.svg';
import menuBg from '../media/menu_bg.jpg';

class Header extends React.Component {
  state = {
    open: false,
  };

  toggle = () => {
    this.setState(
      (state) => ({ open: !state.open }),
      () => {
        if (this.state.open) {
          document.body.classList.add('noscroll');
        } else {
          document.body.classList.remove('noscroll');
        }
      }
    );
  };
  close = () => {
    this.setState(
      (state) => ({ open: false }),
      () => {
        document.body.classList.remove('noscroll');
      }
    );
  };

  renderMobileMenu = () => {
    const { classes } = this.props;
    return (
      <div
        className={classNames(classes.mobileMenuWrapper, 'd-block d-md-none')}
      >
        <Container fluid className={classes.root}>
          <Container className={(classNames(classes.root), 'px-0')}>
            <Row>
              <Col
                xs={{ size: 6, offset: 3 }}
                className={classes.logoContainer}
              >
                <img src={Logo} alt='gaidamak' className='img-fluid' />
              </Col>
              <Col xs='3' className='b-block d-md-none p-0 text-right'>
                <div className={classes.close} onClick={this.toggle}>
                  <span aria-hidden='true'>&times;</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={classes.mobileMenuItems}>
                  <div>
                    <a href='#services' onClick={this.close}>
                      Leistungen
                    </a>
                  </div>
                  <div>
                    <a href='#about' onClick={this.close}>
                      über mich
                    </a>
                  </div>
                  <div>
                    <a href='#gallery' onClick={this.close}>
                      Galerie
                    </a>
                  </div>
                  <div>
                    <a href='#contact' onClick={this.close}>
                      Kontakt
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    if (open) return this.renderMobileMenu();
    return (
      <Container fluid className={classNames(classes.root)}>
        <Container className={(classNames(classes.root), 'px-0')}>
          <Row>
            <Col
              md={{ size: 8, offset: 0 }}
              xs={{ size: 6, offset: 3 }}
              className={classes.logoContainer}
            >
              <img src={Logo} alt='gaidamak' className='img-fluid' />
            </Col>
            <Col md='4' className='d-none d-md-block'>
              <div className={classNames('text-center', classes.kontakt)}>
                <div className={classes.phone}>09153 / 979941</div>
                <div className='text-white'>
                  <a className={classes.email} href='mailto:v.gaidamak@web.de'>
                    v.gaidamak@web.de
                  </a>
                </div>
                <AnchorLink href='#contact' className={classes.toContact}>
                  KONTAKT
                </AnchorLink>
              </div>
              <div
                className={classNames(
                  'd-flex justify-content-between',
                  classes.screenMenu
                )}
              >
                <AnchorLink href='#services'>Leistungen</AnchorLink>
                <AnchorLink href='#about'>über mich</AnchorLink>
                <AnchorLink href='#gallery'>Galerie</AnchorLink>
                <AnchorLink href='#contact'>Kontakt</AnchorLink>
              </div>
            </Col>
            <Col xs='3' className='b-block d-md-none p-0 text-right'>
              <NavbarToggler
                className={classes.toggler}
                onClick={this.toggle}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

const style = (theme) => ({
  close: {
    width: 56,
    height: 56,
    backgroundColor: theme.green,
    position: 'absolute',
    right: 5,
    bottom: 105,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 40,
    color: '#fff',
  },
  root: {
    paddingBottom: 70,
    [theme.bp.down('md')]: {
      paddingTop: 40,
      paddingBottom: 10,
    },
  },
  kontakt: {
    backgroundColor: theme.green,
    '& a': {
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
  phone: {
    fontSize: 20,
    color: '#ffffff',
    paddingTop: 26,
    lineHeight: '24px',
  },
  email: {
    fontSize: 17.4,
  },
  toContact: {
    display: 'inline-block',
    padding: [5, 17],
    fontSize: 18,
    marginTop: 10,
    marginBottom: 26,
    backgroundColor: theme.grey,
    '&:focus': {
      backgroundColor: 'white',
      color: '#000',
    },
    '&:hover': {
      backgroundColor: '#3f3e33',
      color: 'white',
    },
  },
  logoContainer: {
    marginTop: 78,
    [theme.bp.down('md')]: {
      marginBottom: '1.5rem',
      marginTop: 0,
    },
  },
  screenMenu: {
    marginTop: 40,
    '& a': {
      fontSize: 17,
      textTransform: 'uppercase',
      borderBottom: '1px solid',
      lineHeight: '20px',
      color: '#000',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  mobileMenuItems: {
    marginTop: 50,
    fontSize: 23,
    textTransform: 'uppercase',
    textAlign: 'center',
    '& a': {
      marginBottom: 23,
      borderBottom: '1px solid',
      color: '#000',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& div': {
      marginBottom: 23,
    },
  },
  toggler: {
    '& span': {
      backgroundImage: `url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(128, 156, 96, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>")`,
    },
    marginTop: 40,
    '&:focus': {
      outline: 0,
    },
  },
  mobileMenuWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    minHeight: '100vh',
    backgroundImage: `url("${menuBg}")`,
    zIndex: 999,
  },
});

export default injectSheet(style)(Header);
