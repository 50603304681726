import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import bg from '../media/contact.jpg';
import Form from './Form';
import { Parallax } from 'react-parallax';

const sendForm = values => {
  console.log(values);
};

const Contact = ({ classes }) => {
  return (
    <Parallax blur={0} bgImage={bg} bgImageAlt='about' strength={250}>
      <Container fluid className={classNames(classes.root)}>
        <Container className='px-0'>
          <Row>
            <Col md={{ size: 4, offset: 4 }} className={classes.padding}>
              <div className={classes.heading}>KONTAKT</div>
            </Col>
          </Row>
          <Row noGutters>
            <Col md={{ size: 4, offset: 4 }}>
              <Form onSubmit={sendForm} />
            </Col>
          </Row>
        </Container>
      </Container>
    </Parallax>
  );
};

const style = theme => ({
  root: {
    color: '#fff'
  },
  padding: {
    padding: [0, 30]
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    padding: [65, 0, 10],
    color: '#fff',
    borderBottom: `3px solid #fff`,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 38,
    [theme.bp.down('md')]: {
      paddingTop: 58,
      fontSize: 22,
      margin: [0, 42, 17]
    }
  }
});

export default injectSheet(style)(Contact);
