import axios from 'axios';
import qs from 'qs';
import config from '../config';

const post = (data, options = {}) =>
  axios({
    url: config.api,
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data),
    ...options
  });

export default post;
