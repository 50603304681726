import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-snapshot';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import compose from 'recompose/compose';
import thunk from 'redux-thunk';
import api from './api';
import reducer from './ducks';
import { createBreakpoints } from './utils';

import './index.css';
import './custom.scss';

const theme = {
  bp: createBreakpoints(),
  fontSize: 20,
  fontFamily: `'PTSans', sans-serif;`,
  green: '#819e62',
  grey: '#58584b',
  button: {
    color: '#ffffff',
    padding: [12, 17],
    display: 'inline-block',
    backgroundColor: '#58584b',
    fontSize: 19,
  },
};

const composed = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      applyMiddleware(thunk.withExtraArgument(api)),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : applyMiddleware(thunk.withExtraArgument(api));

const store = createStore(reducer, composed);
render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
