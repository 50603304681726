import React from 'react';
import { withRouter } from 'react-router';
import Header from './Page/Header';
import Video from './Page/Video';
import Services from './Page/Services';
import About from './Page/About';
import Gallery from './Page/Gallery';
import Contact from './Page/Contact';
import Footer from './Page/Footer';

class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Video />
        <section id='services'>
          <Services />
        </section>
        <section id='about'>
          <About />
        </section>
        <section id='gallery'>
          <Gallery />
        </section>
        <section id='contact'>
          <Contact />
        </section>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
