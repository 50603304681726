import Immutable from 'seamless-immutable';
// import { SubmissionError } from 'redux-form';
// const prefix = 'App';

const initialState = Immutable({});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
