import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import Slides from './Gallery';

const Gallery = ({ classes }) => {
  return (
    <Container fluid className={classNames(classes.root)}>
      <Container>
        <Row>
          <Col
            md={{ size: 4, offset: 4 }}
            className={classNames(classes.heading)}
          >
            Galerie
          </Col>
        </Row>
        <Slides classes={classes} />
      </Container>
    </Container>
  );
};

const style = theme => ({
  root: {
    paddingTop: 87,
    paddingBottom: 114,
    [theme.bp.down('md')]: {
      paddingTop: 57,
      paddingBottom: 77
    }
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    paddingBottom: 13,
    color: theme.green,
    borderBottom: `3px solid ${theme.green}`,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 95,
    [theme.bp.down('md')]: {
      fontSize: 22,
      paddingBottom: 8,
      margin: [0, 57, 39]
    }
  }
});

export default injectSheet(style)(Gallery);
