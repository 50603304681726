import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import injectSheet from 'react-jss';
import { Parallax } from 'react-parallax';
import bg from '../media/bg.jpg';
import bg_m from '../media/bg_m.jpg';
import decor from '../media/Decor_White_About_Me_Screen.svg';
import { useMediaQuery } from 'react-responsive';

const About = ({ classes }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <Parallax
      blur={0}
      bgImage={isTabletOrMobile ? bg_m : bg}
      bgImageAlt='about'
      strength={100}
    >
      <Container>
        <Row>
          <Col xs={10} md={7} className='text-center'>
            <div className={classes.decor}>
              <img src={decor} alt='' />
            </div>
            <div className={classes.head}>über mich</div>
            <div className={classes.about}>
              Vom Hobby zum Beruf: <br /> Extrembergsteiger - Kletterer - Jäger
              - Baumpfleger
            </div>
          </Col>
        </Row>
      </Container>
    </Parallax>
  );
};

const style = theme => {
  return {
    about: {
      color: '#fff',
      fontSize: 46,
      padding: [48, 0, 150],
      lineHeight: 1.25,
      [theme.bp.down('md')]: {
        fontSize: 26,
        paddingTop: 40
      }
    },
    decor: {
      paddingTop: 105,
      [theme.bp.down('md')]: {
        paddingTop: 57,
        '& img': {
          width: 40
        }
      }
    },
    head: {
      fontSize: 26,
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'uppercase',
      marginTop: 16,
      [theme.bp.down('md')]: {
        fontSize: 22,
        marginTop: 6
      }
    }
  };
};

export default injectSheet(style)(About);
