import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { Row, Col } from 'reactstrap';
import Slide1 from '../../media/gallery/big/1.jpg';
import Slide2 from '../../media/gallery/big/2.jpg';
import Slide3 from '../../media/gallery/big/3.jpg';
import Slide4 from '../../media/gallery/big/4.jpg';
import Slide5 from '../../media/gallery/big/5.jpg';
import Slide6 from '../../media/gallery/big/6.jpg';
import Slide7 from '../../media/gallery/big/7.jpg';
import Slide8 from '../../media/gallery/big/8.jpg';
import Slide9 from '../../media/gallery/big/9.jpg';
import Prewiew1 from '../../media/gallery/prev/Gaydamak_fotos_preview-1.jpg';
import Prewiew2 from '../../media/gallery/prev/Gaydamak_fotos_preview-2.jpg';
import Prewiew3 from '../../media/gallery/prev/Gaydamak_fotos_preview-3.jpg';
import Prewiew4 from '../../media/gallery/prev/Gaydamak_fotos_preview-4.jpg';
import Prewiew5 from '../../media/gallery/prev/Gaydamak_fotos_preview-5.jpg';
import Prewiew6 from '../../media/gallery/prev/Gaydamak_fotos_preview-6.jpg';
import Prewiew7 from '../../media/gallery/prev/Gaydamak_fotos_preview-7.jpg';
import Prewiew8 from '../../media/gallery/prev/Gaydamak_fotos_preview-8.jpg';
import Prewiew9 from '../../media/gallery/prev/Gaydamak_fotos_preview-9.jpg';

const images = [
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
  Slide9
];
const previews = [
  Prewiew1,
  Prewiew2,
  Prewiew3,
  Prewiew4,
  Prewiew5,
  Prewiew6,
  Prewiew7,
  Prewiew8,
  Prewiew9
];

export default class LightboxExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const { classes } = this.props;

    return (
      <Row>
        {previews.map((image, index) => {
          return (
            <Col key={index} md='4' xs='6' className='my-3'>
              <div className={classes.preview}>
                <img
                  src={`${previews[index]}`}
                  onClick={e =>
                    this.setState({ isOpen: true, photoIndex: index })
                  }
                  className='img-fluid cursor-pointer'
                  alt=''
                />
              </div>
            </Col>
          );
        })}

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </Row>
    );
  }
}
