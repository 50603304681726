import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import service1 from '../media/Icon_1_Screen.svg';
import service2 from '../media/Icon_2_Screen.svg';
import service3 from '../media/Icon_3_Screen.svg';
import service4 from '../media/Icon_4_Screen.svg';
import service5 from '../media/Icon_5_Screen.svg';
import service6 from '../media/Icon_6_Screen.svg';

const Services = ({ classes }) => {
  return (
    <Container fluid className={classes.root}>
      <Container>
        <Row>
          <Col
            md={{ size: 4, offset: 4 }}
            className={classNames(classes.heading)}
          >
            MEINE Leistungen
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 10, offset: 1 }}
            className={classNames(classes.services)}
          >
            <Row>
              <Col md={4} className={classNames(classes.service)}>
                <img src={service1} alt='' />
                <p>Riesen- und Problembäume</p>
              </Col>
              <Col md={4} className={classNames(classes.service)}>
                <img src={service2} alt='' />
                <p>Sturmschadensvorbeugung</p>
              </Col>
              <Col md={4} className={classNames(classes.service)}>
                <img src={service3} alt='' />
                <p>Wurzelstockfräsen</p>
              </Col>
            </Row>
            <Row>
              <Col md={4} className={classNames(classes.service)}>
                <img src={service4} alt='' />
                <p>Klettertechnik</p>
              </Col>
              <Col md={4} className={classNames(classes.service)}>
                <img src={service5} alt='' />
                <p>Astabseilung</p>
              </Col>
              <Col md={4} className={classNames(classes.service)}>
                <img src={service6} alt='' />
                <p>Heckenschnitt</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const style = theme => ({
  root: {
    paddingTop: 100,
    [theme.bp.down('md')]: {
      paddingTop: 55
    }
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    paddingBottom: 10,
    color: theme.green,
    borderBottom: `3px solid ${theme.green}`,
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.bp.down('md')]: {
      fontSize: 22,
      margin: [0, 56]
    }
  },
  services: {
    paddingBottom: 93,
    [theme.bp.down('md')]: {
      marginTop: 24,
      paddingBottom: 64
    }
  },
  service: {
    marginTop: 58,
    fontSize: 21,
    color: '#57574a',
    textAlign: 'center',
    '& p': {
      margin: [25, 0]
    },
    '& img': {
      width: 95,
      height: 95,
      margin: '0px auto'
    },
    [theme.bp.down('md')]: {
      marginTop: 20,
      '& img': {
        width: 67,
        height: 67
      },
      '& p': {
        margin: [20, 0, 0],
        fontSize: 16
      }
    }
  }
});

export default injectSheet(style)(Services);
